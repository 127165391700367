@font-face {
  font-family: road_rage;
  src: url("./Road_Rage.otf");
}

@font-face {
  font-family: louis_george;
  src: url("./louis-george-cafe.ttf");
}

body {
  background: #f1f5f8;
  color: #063251;
  font-family: louis_george;
}

h3 {
  font-size: 3.5rem;
  font-family: road_rage;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #063251;
  letter-spacing: 3px;
  margin-bottom: 32px;
}

input {
  padding: 0.25rem 0.5rem;
  width: 3rem;
  border-radius: 0.25rem;
  border: none;
  margin: 0 0.5rem;
  font-size: 1.25rem;
}

form {
  text-align: center;
  margin-bottom: 64px;
}

p {
  font-size: 1.25rem;
  text-align: center;
}

.dummy-text {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  text-transform: uppercase;
  background: #49a6e9;
  color: #063251;
  padding: 0.375rem 0.75rem;
  letter-spacing: 1px;
  display: inline-block;
  transition: all 0.3s linear;
  font-size: 0.875rem;
  border: 2px solid #49a6e9;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

@media screen and (min-width: 320px) and (max-width: 400px) {
  h3 {
    font-size: 2.5rem;
  }
}
